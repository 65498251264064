<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div v-if="!isLoading && taxRateGroup">
      <router-link :to="{ name: 'settings-services' }" icon='arrowdownlarge' class="is-back"><p><i class="backarrow"></i>BACK</p></router-link>
      <h1 v-if="id">Edit Tax Rate Group</h1>
      <h1 v-else>Create Tax Rate Group</h1>
      <div v-if="id"></div>
      <div class="notification is-light-passive" v-else><b>Example</b><br>Create 3 taxes in the group. 1 For New York State at 4%, one for New York City at 4.5% and one for the Metropolitan Commuter Transportation District at 0.38%. The combined sales tax would be 8.88%</div>
      <hr>
      <b-field class="modal-label" label="Tax Rate Group Name">
        <b-input v-model="taxRateGroup.name" type="text" placeholder="e.g. My Product Sales Taxes"/>
      </b-field>
      <h3>Taxes</h3>
      <div class="columns is-mobile" v-for="(taxRate, index) in taxRateGroup.taxRates" v-bind:key="index">
        <div class="column is-two-thirds">
          <b-field label="Name" class="modal-label" expanded>
            <b-input v-model="taxRate.name" type="text" placeholder="e.g. NYC Sales Tax"/>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Rate" expanded class="modal-label">
            <b-input class="input-text-right" icon-right="percent" v-model="taxRate.rate" type="text" placeholder="e.g 5%"/>
          </b-field>
        </div>
      </div>
      <b-button type="is-light is-small" @click="addTaxRate">Add Tax Rate</b-button>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: { Breadcrumbs },
  name: 'TaxRateGroupEdit',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Tax Rate Groups', route: 'settings-tax-rate-groups' },
        { name: 'Edit Tax Rate Group', route: null }
      ],
      isLoading: false,
      taxRateGroup: {
        name: '',
        taxRates: [
          {
            name: '',
            rate: 0.00
          }
        ]
      }
    }
  },
  computed: {},
  methods: {
    addTaxRate () {
      const emptyTaxRate = {
        id: null,
        name: '',
        rate: 0.00
      }
      this.taxRateGroup.taxRates.push(emptyTaxRate)
    },
    cancel () {
      this.$router.push({ name: 'settings-tax-rate-groups' })
    },
    reloadTaxRateGroup () {
      if (this.id) {
        this.isLoading = true
        this.$store.dispatch('settings/fetchTaxRateGroup', this.id)
          .then((taxRateGroup) => {
            this.taxRateGroup = taxRateGroup
            this.isLoading = false
          })
      }
    },
    save () {
      this.$store.dispatch('settings/saveTaxRateGroup', this.taxRateGroup)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Tax rate group saved.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-tax-rate-groups' })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 5000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000,
              queue: false
            })
          }
        })
    }
  },
  mounted () {
    this.reloadTaxRateGroup()
  },
  props: ['id']
}
</script>

<style lang="scss" scoped>

.notification {
  margin-top: 20px;
  border-radius: 20px;
}

</style>
